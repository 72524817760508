import React, { ReactNode } from "react"

import * as styles from "./index.module.scss"

import Header from "@components/Header"
import Footer from "@components/Footer"
interface layoutType {
    children: ReactNode[] | ReactNode
}

export default function Layout(params: layoutType) {
    let { children } = params
    return (
        <div className={styles.layout}>
            <header>
                <Header />
            </header>

            <main>
                {children}
            </main>

            <footer>
                <Footer />
            </footer>
        </div>
    )
}