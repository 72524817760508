exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-index-tsx": () => import("./../../../src/pages/aboutus/index.tsx" /* webpackChunkName: "component---src-pages-aboutus-index-tsx" */),
  "component---src-pages-advertising-index-tsx": () => import("./../../../src/pages/advertising/index.tsx" /* webpackChunkName: "component---src-pages-advertising-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketing-force-index-tsx": () => import("./../../../src/pages/marketing-force/index.tsx" /* webpackChunkName: "component---src-pages-marketing-force-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-solution-index-tsx": () => import("./../../../src/pages/solution/index.tsx" /* webpackChunkName: "component---src-pages-solution-index-tsx" */)
}

