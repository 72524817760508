// extracted by mini-css-extract-plugin
export var footer = "index-module--footer--jqoP-";
export var footer_main = "index-module--footer_main---FvSO";
export var first = "index-module--first--czM+s";
export var mail = "index-module--mail--hyukH";
export var left = "index-module--left--ZlOJH";
export var right = "index-module--right--28Nbz";
export var list = "index-module--list--UURUm";
export var title = "index-module--title--jpEde";
export var line = "index-module--line--A+rKl";
export var copyright = "index-module--copyright--OHPLa";
export var mobile = "index-module--mobile--EdfdS";